import { on_ready } from 'src/js/utils';
import ToggleHeight from "src/classes/ToggleHeight";

function init(){
    document.querySelectorAll('.ff_toggle_height').forEach(container=>{
        
        if( typeof container.ff_toggle_height !== 'undefined' ) return;

        let trigger = container.querySelector('.ff_toggle_height_trigger');
        let target = container.querySelector('.ff_toggle_height_target');
        if( !trigger || !target ) return;
        
        container.ff_toggle_height = new ToggleHeight({
            trigger,
            target,
        });

    })
}

on_ready(init);